.dropdown {
	border: 0.5px solid #5b2e9d;
	box-shadow: 0px 0px 50px rgba(91, 46, 157, 0.25);
	border-radius: 10px;
}

.nft__div {
	background: rgba(0, 0, 0, 0.5);
	border: 1px solid #5b2e9d;
}

.partner__div {
	background: linear-gradient(91.5deg, rgba(91, 46, 157, 0.15) 2.36%, rgba(86, 16, 16, 0.15) 101.4%);
	backdrop-filter: blur(7.5px);
	border-radius: 20px;
}


.bid__button {
	width: 139px;
	height: 36px;
	border: 3px solid #5b2e9d;
	border-radius: 30px;
}

/* .creators__card {
	width: 296px;
	height: 107px;
	background: rgba(91, 46, 157, 0.15);
	border: 1px solid #5B2E9D;
	border-radius: 60px;
} */

ul a.active {
	color: #5B2E9D;
	border-bottom: 1px solid #5B2E9D;
}

.launch__bg {
	background-image: url('rectangle.png');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.timer {
	align-items: center;
	justify-content: center;
}

.timer div:last-child {
	display: flex;
	align-items: center;
	gap: 10px;
}

.dropdown__menu {
	border: 1px solid #5B2E9D;
}

input#file-input {
	display: none;
}

input#dp-input {
	display: none;
}

.upcoming{
	background: rgba(38, 34, 40, 0.76);
	position: relative;
	top:3.4em;
	height: 15em;
	margin-bottom: 3em;
	width: 100%;
	padding: 2.5em;

}


@media only screen and (max-width: 992px){
	
	
}